body {
  margin: 0;
}


.App {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ParallaxImage {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: var(--color);
  row-gap: 4rem;
  text-align: center;
}

.Image1 {
  background-image: url("../public/images/IMG-20220227-WA0006.jpg");
}

.Image2 {
  background-image: url("../public/images/IMG-20220227-WA0001.jpg");
}

.Image3 {
  background-image: url("../public/images/IMG-20220227-WA0002.jpg");
}

.Image4 {
  background-image: url("../public/images/IMG-20220227-WA0021.jpg");
}

.ParallaxHeader {
  font-size: 2vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
  background-color: #333;
  color: white;
  min-height: 8vh;
  max-height: 30vh;
  text-align: center;
  font-family: 'Noto Sans Mono', monospace;
}

.MainContent {
  font-size: 1.5vh;
  padding: 1vh;
  padding-top: 50px;
  background-color: #333;
  color: white;
  min-height: 20vh;
  max-height: 40vh;
  text-align: center;
  font-family: 'Noto Sans Mono', monospace;
}

.PadHeader {
  padding-top: 1300px;
}


.ProjectImage {
  width: 100%;
  max-height: 80vh;
  object-fit: contain;
  position: relative;
}

.BackgroundImage {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  filter: blur(5px);
  position: absolute;
}

.carousel-item {
  position: relative;
}

.carousel-caption {
  position: absolute;
  background-color: #333;
  color: white;
  left: 50%;
  margin: auto;
  max-width: 50vh;
  border-radius: 10px;
}

.navbar-brand {
  text-align: center;
}